import { useState } from 'react'

type UseDialogReturn = [boolean, () => void, () => void]

const useDialog = (initialState?: boolean): UseDialogReturn => {
  const [isOpen, setIsOpen] = useState(!!initialState)

  const open = () => setIsOpen(true)

  const close = () => setIsOpen(false)

  return [isOpen, open, close]
}

export default useDialog
